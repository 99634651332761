<template>
  <div id="project-column">
    <notes-dialog @close-notes-dialog="displayNotesModal=false" :displayNotesModal="displayNotesModal"
                  @update:visible="displayNotesModal = false"/>
    <div class="row">
      <h3 class="column-title">{{ $t('project') }}</h3>
      <Button v-if="isHauratonUser" class="p-button-sm" :disabled="hasStartedAdvancedCalculation" id="switch-btn"
              @click="$emit('switchDisplay')"
              :label="$t(!displayCatchmentArea ? 'switchToAdvanced' : 'switchToStandard')"/>
    </div>
    <div class="wrap">
      <div id="top-btns">
        <Button class="p-button-sm twin"
                :label="$t('new')" @click="$emit('newProject')"/>
        <Button class="p-button-sm twin"
                :label="$t('load.button')" @click="$emit('loadProject')"/>
      </div>
      <Button class="p-button-sm" id="save-button"
              :label="$t('save.button')" @click="$emit('saveProject')"/>
    </div>
    <div id="project-name-div">
      <span id="project-name" class="p-float-label">
          <InputText type="text" id="project-name-input" v-model="projectName" :placeholder="$t('projectName')"/>
      </span>
      <i :class="['pi', showAccordion ? 'pi-minus-circle' : 'pi-plus-circle']" id="accordion-button"
         v-on:click="showAccordion = !showAccordion;"></i>
    </div>
    <div class="panel" v-show="showAccordion">
      <label class="detail">{{ $t('location') }}</label>
      <InputText type="text" v-model="location"/>
      <label class="detail">{{ $t('reference') }}</label>
      <InputText type="text" v-model="reference"/>
      <label class="detail">{{ $t('date') }}</label>
      <InputText type="text" v-model="date"/>
      <label class="detail">{{ $t('company') }}</label>
      <InputText type="text" v-model="company"/>
      <label class="detail">{{ $t('designer') }}</label>
      <InputText type="text" v-model="designer"/>
      <label class="detail">{{ $t('telephone') }}</label>
      <InputText type="text" v-model="telephone"/>
      <label class="detail">{{ $t('email') }}</label>
      <InputText type="text" v-model="email"/>
    </div>
    <div id="datatable" :style="{height: datatableHeight}">
      <DataTable :value="runs" dataKey="id" :selection="selectedRun" selectionMode="single"
                 class="p-datatable-sm editable-cells-table" responsiveLayout="scroll"
                 editMode="cell" @rowReorder="onRowReorder" @row-click="$emit('changeRun', $event.data)">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false"/>
        <Column v-for="col in columns" field="name" :key="col.id" headerStyle="width: 17rem">
          <template #editor="slotProps">
            <InputText :model-value="slotProps.data[slotProps.column.props.field]" id="run-text"
                       @update:model-value="this.$store.commit('renameRun', {
                         id: slotProps.data.id, name: $event,
                       })" @focus="$event.target.select()"/>
          </template>
        </Column>
        <Column>
          <template #body="slotProps">
            <Button icon="pi pi-trash" class="trash-bin"
                    style="width: 30px; height: 30px; margin: 0"
                    @click="$emit('deleteRun', slotProps.data)"/>
          </template>
        </Column>
      </DataTable>
    </div>
    <Divider class="divider"/>
    <div class="action-buttons">
      <div class="row">
        <Button v-bind:class="{'needs-recalculation': canCalculate, 'p-button-sm': true}"
                style="width: 100px"
                @click="handleCalculate" :disabled="isCalculateDisabled">
          <Calculator/> <span class="p-button-label">{{ $t('calculate') }}</span>
        </Button>
      </div>
      <div class="row">
        <Button :label="$t('addRunLabel')" style="width: 132px" class="p-button-sm"
                @click="$emit('addRun')" :disabled="!isCalculated"></Button>
        <Tooltip :text="$t('addRunLabelTT')"/>
        <Button :label="$t('addNotesLabel')" style="width: 132px" class="p-button-sm"
                @click="displayNotesModal = true" :disabled="!isCalculated"></Button>
        <Tooltip :text="$t('addNotesLabelTT')"/>
      </div>
      <div class="row">
        <Button :label="$t('resetLabel')" style="width: 132px" class="p-button-sm"
                @click="handleResetClick"></Button>
        <Tooltip :text="$t('resetLabelTT')"/>
        <Button :label="$t('duplicateLabel')" style="width: 132px" class="p-button-sm"
                @click="$emit('duplicateRun')" :disabled="!isCalculated"></Button>
        <Tooltip :text="$t('duplicateLabelTT')"/>
      </div>
    </div>
    <reset-inflows-and-accessories-warning :displayPositionWarning="displayPositionWarning"
                                           @close-position-warning="displayPositionWarning = false"/>
  </div>
</template>


<script>
import NotesDialog from "../main_content/NotesDialog";
import ResetInflowsAndAccessoriesWarning from "../main_content/ResetInflowsAndAccessoriesWarning";
import Tooltip from "@/components/main_content/tabs_content/design_run/Tooltip";
import Calculator from '@/assets/images/calculator.svg?inline';
import {mapGetters} from "vuex";

export default {
  name: "ProjectColumn",
  components: {Calculator, Tooltip, ResetInflowsAndAccessoriesWarning, NotesDialog},
  props: ['isHauratonUser', 'displayCatchmentArea'],
  emits: ['newProject', 'loadProject', 'saveProject', 'switchDisplay', 'changeRun', 'deleteRun',
    'calculate', 'addRun', 'duplicateRun', 'setAction'],
  data() {
    return {
      displayNotesModal: false,
      showAccordion: false,
      columns: [{field: 'name', header: 'Name'}],
      windowWidth: window.innerWidth,
      displayPositionWarning: false,
    }
  },

  computed: {
    ...mapGetters([
      'selectedRun',
      'needsLoading',
      'needsGrating',
    ]),
    datatableHeight() {
      if (this.windowWidth < 1900) {
        if (!this.showAccordion) {
          return '350px'
        } else {
          return '113px'
        }
      } else {
        if (!this.showAccordion) {
          return '505px'
        } else {
          return '212px'
        }
      }
    },
    canCalculate() {
      return (this.$store.getters.getHasInputChanged && this.$store.getters.isSelectedRunCalculated) || this.$store.getters.readyForFirstCalculation;
    },
    hasStartedAdvancedCalculation() {
      const selectedRun = this.$store.getters.selectedRun;
      //Don't allow switching back to normal if the design has any hand picked channels
      //Don't allow switching to advanced if point products are being used, there's nothing extra there for them
      //Don't allow switching back to normal if concrete channels are being used, they only work in advanced
      return selectedRun.channels.length > 0 ||
          (selectedRun.selectedSystem && selectedRun.selectedSystem.product_type !== 'C');
    },
    isCalculateDisabled() {
      const selectedRun = this.selectedRun;

      // Make sure there is a channel group set before looking any further
      if (!selectedRun.selectedSystem) return true;

      //Make sure there is any rain to drain
      if (selectedRun.rainfall.rainfall_mm_per_hr <= 0) return true;

      // Make sure a loading & grating have been specified (if needed)
      if (this.needsLoading && !selectedRun.loading || this.needsGrating && !selectedRun.grating) return true;

      // Make sure an area to drain has been specified
      if (!selectedRun.drainage_area_m2) return true;

      // validate longitudinal slope
      if (this.$store.getters.getGroundSlope < 0 || this.$store.getters.getGroundSlope > selectedRun.maxSlopeAngle) {
        return true
      }


      switch (selectedRun.selectedSystem.product_type) {
        case 'P':
          console.assert(!this.displayCatchmentArea, 'Using point products on advanced mode?');

          return selectedRun.drainage_area_m2 <= 0;

        case 'M': {
          console.assert(this.displayCatchmentArea, 'Using concrete channel on standard mode?');
          const {width, depth} = selectedRun.concreteChannel;
          if (width <= 0 || depth <= 0) return true;
        }
        // eslint-disable-next-line no-fallthrough
        default:
          if (!this.displayCatchmentArea) {
            // NORMAL MODE - no check for the user (internal/external) on
            // place since only Hauraton users will see the button to switch to
            // advanced mode
            if (selectedRun.channelLength <= 0 || selectedRun.channelLength > selectedRun.maxChannelLength) return true;
          } else {
            // ADVANCED MODE
            if (selectedRun.channels.length === 0 ||
                selectedRun.channelLength <= 0 || selectedRun.channelLength > selectedRun.maxChannelLength) {
              // Make sure there is at least one channel for this run
              return true
            }

            switch (selectedRun.dischargeSetting) {
              case 'outflow-in-trashbox':
                if (!selectedRun.outflowTrashbox) return true;
                break;
              case 'end-cap-with-outlet':
                if (!selectedRun.outflowEndcap) return true;
                break;
            }
          }
      }
      return false
    },
    isCalculated() {
      return this.$store.getters.getIsCalculated
    },
    projectName: {
      get() {
        return this.$store.state.project.details.name;
      },
      set(value) {
        this.$store.commit('setProjectName', value);
      }
    },
    location: {
      get() {
        return this.$store.state.project.details.location;
      },
      set(value) {
        this.$store.commit('setLocation', value);
      }
    },
    reference: {
      get() {
        return this.$store.state.project.details.reference;
      },
      set(value) {
        this.$store.commit('setReference', value);
      }
    },
    date: {
      get() {
        return this.$store.state.project.details.date;
      },
      set(value) {
        this.$store.commit('setDate', value);
      }
    },
    company: {
      get() {
        return this.$store.state.project.details.company;
      },
      set(value) {
        this.$store.commit('setCompany', value);
      }
    },
    designer: {
      get() {
        return this.$store.state.project.details.designer;
      },
      set(value) {
        this.$store.commit('setDesigner', value);
      }
    },
    telephone: {
      get() {
        return this.$store.state.project.details.telephone;
      },
      set(value) {
        this.$store.commit('setTelephone', value);
      }
    },
    email: {
      get() {
        return this.$store.state.project.details.email;
      },
      set(value) {
        this.$store.commit('setEmail', value);
      }
    },
    runs() {
      return this.$store.getters.runs;
    },
  },
  watch: {
  },
  methods: {
    handleResetClick() {
      this.$store.commit('resetRunData')
      this.$emit('setAction', 'catchment')
    },
    onRowReorder(event) {
      this.$store.commit('reorderRuns', event.value)
    },
    handleCalculate() {
      // Make sure that if the channel length has been changed all the
      // existing inflows/accessories have a distance from discharge that
      // is not greater than the new channel length, OTHERWISE RESET EVERYTHING!
      const currentChannelLength = this.$store.getters.getChannelLength
      let lostInflows = this.$store.getters.getInflows.some(inflow => inflow.distance_m > currentChannelLength);
      let lostAccessories = this.$store.getters.getAccessories.some(acc => acc.distance_m > currentChannelLength);
      if (lostInflows || lostAccessories) {
        this.displayPositionWarning = true
        this.$store.commit('resetInflows')
        this.$store.commit('resetAccessories')
      }

      this.$emit('calculate');
    }
  },
}
</script>

<style scoped lang="scss">
@use "sass:color";

.p-accordion-header {
  height: 0 !important;
}

#project-column {
  /*border: 1px solid orange;*/
  display: flex;
  flex-direction: column;
  width: 326px;
  height: 88vh;
}


.column-title {
  margin: 0.5rem
}

button {
  margin: 0.5rem;
  padding: 2px 4px !important;
  width: 5rem;
}

#project-name-input {
  width: 100%;
  max-width: 17rem;
  text-align: left;
}

#project-name-div {
  margin-right: .5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#project-description-accordion {
  margin: 0 0.5rem 1rem 0.5em
}

#datatable {
  margin: 0 0.5rem;
  height: 30rem;
  overflow-y: scroll;
}

#datatable ::v-deep(.p-datatable-reorderablerow-handle) {
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}

::v-deep(.editable-cells-table td.p-cell-editing) {
  padding-top: 0;
  padding-bottom: 0;
}

#run-text {
  width: 100px
}

.noHeader.ui-datatable table thead tr {
  display: none;
}

.row {
  display: flex;
  align-items: center;
}

.divider {
  margin: 0.5rem !important;
}

th {
  display: none !important;
}

td {
  height: 0.2rem !important;
}

.accordion {
  background-color: #000000;
  color: #000000;
  cursor: pointer;
  width: 90%;
  text-align: left;
  border: none;
  outline: none;
  transition: 0.4s;
  margin: 0 auto;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
.active, .accordion:hover {
  background-color: #ccc;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  display: grid;
  grid-template-columns: min-content auto;
  grid-template-rows: auto;
  gap: 0.5rem 1.5rem;
  padding: 0.5rem;
  border: 1px solid #eeeeee;
  margin: 0 0.5rem;
  font-size: 0.8rem;

  input {
    font-size: 0.7rem;
    margin: 0;
    text-align: left;
  }
}

::v-deep(.trash-bin.p-button) {
  background-color: transparent;
  color: #000000;
  border: none;
}

input {
  padding: 2px;
  text-align: right;
  margin: 0.5rem 0;
}

input, span {
  padding: 2px;
}

#project-name {
  margin: 0 6px;
  width: 100%;
}

svg {
  flex-shrink: 0;
}

#save-button, button.p-button.needs-recalculation {
  $colour: #B7182E; //var(--red)
  background-color: $colour;
  border-color: $colour;

  &:enabled:hover {
    background-color: color.scale($colour, $blackness: 15%);
    border-color: color.scale($colour, $blackness: 15%);
  }

  &:enabled:active {
    background-color: color.scale($colour, $blackness: 30%);
    border-color: color.scale($colour, $blackness: 30%);
  }
}

.wrap {
  display: flex
}

#switch-btn {
  width: 12rem;
  display: flex;
  justify-content: center;
}

  .red {
    border: 2px solid red;
  }
  .transparent {
    border: 2px solid transparent;
  }

@media (max-width: 1900px) {

  .detail {
    text-align: left;
    width: 100%;
  }
  input {
    margin: 0
  }

  ::v-deep(.p-editable-column) {
    font-size: 0.8rem;
  }

  #save-button {
    width: calc(100% - 1rem);
  }

  .twin {
    width: 50%;
  }
  #top-btns {
    display: flex;
    flex-direction: row;
  }

  .wrap {
    flex-direction: column;
  }




}
</style>
