<template>
  <div id="design-specification-form">
    <h4>{{ $t('designSpecification') }}</h4>
    <table>
      <tr>
        <td class="title">{{ $t('selectedSystem') }}</td>
        <td>{{ selectedRun.channel_group }}</td>
      </tr>
      <template v-if="!isPointProduct">
        <tr>
          <td>{{ $t('channelNominalWidth') }}</td>
          <td><template v-if="!selectedRun.results.missing_han">
            {{ selectedRun.results.channel_internal_width_mm }}mm
          </template></td>
        </tr>
        <tr>
          <td>{{ $t('channelType') }}</td>
          <td>{{ selectedRun.results.channel_product_type }}</td>
        </tr>
        <tr>
          <td>{{ $t('invertDepth') }}</td>
          <td>{{ selectedRun.results.invert_depth_mm }}mm</td>
        </tr>
        <tr v-if="selectedRun.selectedSystem?.product_type !== 'M'">
          <td>{{ $t('overallDepth') }}</td>
          <td><template v-if="!selectedRun.results.missing_han">
            {{ selectedRun.results.overall_depth_mm }}mm
          </template></td>
        </tr>
        <tr v-else>
          <td>{{ $t('channelRoughness') }}</td>
          <td>{{ selectedRun.concreteChannel.roughness }}</td>
        </tr>
        <tr v-if="needsLoading">
          <td>{{ $t('loading') }}</td>
          <td>{{ selectedRun.results.system_loading }}</td>
        </tr>
        <tr>
          <td>{{ $t('flowVelocity') }}</td>
          <td>{{ selectedRun.results.flow_velocity?.toFixed(1) }} m/s</td>
        </tr>
        <tr>
          <td>{{ $t('channelDischarge') }}</td>
          <td>{{ selectedRun.results.channel_discharge?.toFixed(2) }} l/s<template v-if="sharedTrashboxOutflow">
              (+ {{ sharedTrashboxOutflow.toFixed(2) }} l/s)
          </template></td>
        </tr>
        <tr v-if="selectedRun.results.overflowed">
          <td>{{ $t('floodingRate') }}</td>
          <td>{{ (selectedRun.results.channel_discharge - selectedRun.results.max_possible_discharge).toFixed(2) }} l/s</td>
        </tr>
        <tr v-else>
          <td>{{ $t('freeboard') }}</td>
          <td>{{ Math.round(selectedRun.results.freeboard) }} mm</td>
        </tr>
        <tr>
          <td>{{ $t('maxDischargeFlow') }}</td>
          <td>{{ selectedRun.results.max_possible_discharge?.toFixed(1) }} l/s</td>
        </tr>
        <tr>
          <td>{{ $t('usedVolume') }}</td>
          <td v-if="!selectedRun.results.overflowed">
            {{ selectedRun.results.freeboard_percent?.toFixed(0) }} %</td>
          <td v-else style="color: red">{{ selectedRun.results.percentage_full?.toFixed(0) }} %</td>
        </tr>
        <tr>
          <td>{{ $t('totalSystemVolume') }}</td>
          <td>{{ selectedRun.results.total_system_volume?.toFixed(2) }} m<sup>3</sup></td>
        </tr>
        <tr>
          <td>{{ $t('installedGradient') }}</td>
          <td class="flex">
            <span>{{ selectedRun.results.installed_gradient?.toFixed(3) }} %</span>
            <Tooltip :text="$t('installedGradientTT')"/>
          </td>
        </tr>
        <tr v-if="this.needsGrating">
          <td>{{ $t('grating.description') }}</td>
          <td>{{ selectedRun.results.grating?.description }}</td>
        </tr>
        <tr v-if="this.needsGrating">
          <td>{{ $t('grating.slot_dimensions') }}</td>
          <td>{{ selectedRun.results.grating?.slot_dimensions }}</td>
        </tr>
        <tr v-if="this.needsGrating">
          <td>{{ $t('grating.open_area') }}</td>
          <td>{{ selectedRun.results.grating?.open_area }}cm<sup>2</sup></td>
        </tr>
        <tr v-if="this.needsGrating">
          <td>{{ $t('grating.length') }}</td>
          <td>{{ selectedRun.results.grating?.length }}</td>
        </tr>
      </template>
      <template v-else>
        <tr>
          <td>{{ $t('dischargeFlow') }}</td>
          <td>{{ selectedRun.results.flow?.toFixed(1) }} l/s</td>
        </tr>
      </template>
    </table>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import Tooltip from "@/components/main_content/tabs_content/design_run/Tooltip";

export default {
  name: "DesignSpecifications",
  components: {Tooltip},
  computed: {
    ...mapGetters([
      'selectedRun',
      'needsLoading',
      'needsGrating',
      'sharedTrashboxOutflow',
    ]),
    isPointProduct() {
      return this.selectedRun.selectedSystem?.product_type === 'P';
    },
  },
}
</script>

<style scoped>
#design-specification-form {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 0.5rem;
}

h4 {
  margin: 0 0 0.5rem 0;
}

table, td {
  /*border: 1px solid red;*/
  border-collapse: collapse;
}

table {
  width: 100%;
  padding: 0.5rem;
}

td {
  width: 50%;
  padding: 0.4rem;
  font-size: 0.7rem;
  text-align: left;
}

td:first-child {
  font-weight: 500;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

tr:nth-child(odd) {
  background: #f6f6f6;
}

p {
  font-size: 0.7rem;
  margin-bottom: 0;
}

@media (max-width: 1900px) {
}
</style>
